import {
  EMAIL_REGEX,
  FREE_TEXT_REGEX,
  PASSWORD_REGEX,
  VERIFICATION_CODE_REGEX,
} from '../regexConstants'

export default [
  {
    name: 'email',
    label: 'Email or Candidate ID',
    errorMessage: 'Enter your Email or Candidate ID',
    required: true,
    type: 'text',
    pattern: FREE_TEXT_REGEX,
    level: 1,
  },
  {
    name: 'password',
    label: 'Password',
    errorMessage: 'Enter password (min 6 characters)',
    required: true,
    type: 'password',
    pattern: PASSWORD_REGEX,
    level: 1,
  },
  {
    name: 'password',
    label: 'Password',
    errorMessage: 'Enter password (min 6 characters)',
    required: true,
    type: 'password',
    pattern: PASSWORD_REGEX,
    level: 2,
  },
  {
    name: 'confirmPassword',
    label: 'Re-enter Password',
    errorMessage: 'Re-enter password(should be same as above)',
    required: true,
    type: 'password',
    pattern: PASSWORD_REGEX,
    level: 2,
  },
]
