import React from 'react'
import Login from '@/modules/UserAuthentication/Login'
import Meta from '@/components/Meta'

const SignIn = () => {
  return (
    <>
      <Meta title="Login into your Account" />
      <Login />
    </>
  )
}

export default SignIn
